import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
    formatEvents: {id: string, format: '5 мин' | '1 час' | '24 часа'}[],
}

const initialState: InitialState = {
    formatEvents: [],
};

export const changeFormatEventsSlice = createSlice({
    name: 'changeFormatEvents',
    initialState,
    reducers: {
        setFormatsEvents: (state, action: PayloadAction<{id: string, format: '5 мин' | '1 час' | '24 часа'}[]>) => {
            state.formatEvents = action.payload;
        },
        changeFormatEvents: (state, action: PayloadAction<{id: string, format: '5 мин' | '1 час' | '24 часа'}>) => {
            const newFormat = state.formatEvents.find(item => item.id === action.payload.id);
            if (newFormat) {
                newFormat.format = action.payload.format;
            }
        },
    },
});

export const {
    setFormatsEvents,
    changeFormatEvents,
} = changeFormatEventsSlice.actions;

export const selectFormatEvents = (state: RootState) => state.formatEvents;

export default changeFormatEventsSlice.reducer;