import { VideoTypesMHD } from 'src/microservices/client/mhd/types/VideoTypesMHD';
import { api } from '../api';
import { EventsTypesMHD } from 'src/microservices/client/mhd/types/EventsTypesMHD';

export const monitorApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getMonitorVideos: builder.query<VideoTypesMHD[], void>({
            query: () => ({
                url: 'video/monitor/videos',
                method: 'GET',
            }),
        }),
        getMonitorEvents: builder.query<EventsTypesMHD[], {id: string, from: number, to: number, summ: false | '5min' | '60min' | '24h'}>({
            query: ({ id, from, to, summ }) => ({
                url: `video/monitor/events?id=${id}&from=${from}&to=${to}${summ ? `&summ=${summ}` : ''}`,
                method: 'GET',
            }),
        }),
        addMonitorVideo: builder.mutation<any, any>({
            query: (urls) => ({
                url: 'video/monitor/videos',
                method: 'POST',
                body: JSON.stringify(urls),
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),
        deleteMonitorVideo: builder.query<any, any>({
            query: (id) => ({
                url: `video/monitor/videos?vid=${id}`,
                method: 'DELETE',
            }),
        }),
        disableCollData: builder.mutation<any, any>({
            query: (id) => ({
                url: 'video/monitor/disable',
                method: 'PATCH',
                body: id
            }),
        }),
        enableCollData: builder.mutation<any, any>({
            query: (id) => ({
                url: 'video/monitor/enable',
                method: 'PATCH',
                body: id
            }),
        }),
        addMonitorGroup: builder.mutation<any, any>({
            query: ({id, group}) => ({
                url: `video/monitor/setVideoGroup?id=${id}&newGroupContent=${group}`,
                method: 'PATCH',
                body: id
            }),
        }),
    }),
});

export const {
    useGetMonitorVideosQuery,
    useLazyGetMonitorEventsQuery,
    useAddMonitorVideoMutation,
    useLazyDeleteMonitorVideoQuery,
    useDisableCollDataMutation,
    useEnableCollDataMutation,
    useAddMonitorGroupMutation
} = monitorApi;

export const {
    endpoints: {},
} = monitorApi;
