import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    refetchAfterAddVideo: false,
    refetchAfterDeleteVideo: false,
    refetchAfterAddGroup: false,
    refetchAfterChangeFormat: false,
};

export const refetchingSliceMHD = createSlice({
    name: 'labeled',
    initialState,
    reducers: {
        setRefetchAfterAddVideo: (state, action: PayloadAction<boolean>) => {
            state.refetchAfterAddVideo = action.payload;
        },
        setRefetchAfterDeleteVideo: (state, action: PayloadAction<boolean>) => {
            state.refetchAfterDeleteVideo = action.payload;
        },
        setRefetchAfterAddGroup: (state, action: PayloadAction<boolean>) => {
            state.refetchAfterAddGroup = action.payload;
        },
    },
});

export const {
    setRefetchAfterAddVideo,
    setRefetchAfterDeleteVideo,
    setRefetchAfterAddGroup,
} = refetchingSliceMHD.actions;

export const selectRefetching = (state: RootState) => state.refetchingMHD;

export default refetchingSliceMHD.reducer;