import { configureStore } from '@reduxjs/toolkit'
import { channelsApi } from './services/queries/channels'
import { videosApi } from './services/queries/videos'
import { badgeApi } from './services/queries/badges'
import { userApi } from './services/queries/user'
import { videoFlowApi } from './services/queries/videoflow'
import { competitorsApi } from './services/queries/competitors-def'
import { monitorApi } from './services/queries/monitor'
import { api } from './services/api'

import videoflow from '../features/video-flow/videoflowSlice'
import playlists from '../features/video-flow/platlistsSlice'
import sortAndFilterVideoFlow from '../features/video-flow/sortAndFilterSlice'
import dropDownRadio from '../features/video-flow/dropDownRadioSlice'
import filtered from '../features/channels/filteredByDateSlice'
import labeled from '../features/competitors/labelDataSlice'
import refetching from '../features/competitors/refetchingSlice'
import refetchingMHD from '../features/monitor/refetchSlice'
import groupList from '../features/competitors/groupListSlice'
import annotations from '../features/monitor/changeAnnotationsSlice'
import formatEvents from '../features/monitor/changeFormatEventsSlice'
import user from '../features/user'
// manegment
import manegmentPage from '../features/manegment/manegmentPagSlice'

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        videoflow,
        playlists,
        sortAndFilterVideoFlow,
        dropDownRadio,
        manegmentPage,
        filtered,
        labeled,
        refetching,
        refetchingMHD,
        groupList,
        annotations,
        formatEvents,
        user
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        })
            .concat(channelsApi.middleware)
            .concat(videosApi.middleware)
            .concat(userApi.middleware)
            .concat(badgeApi.middleware)
            .concat(videoFlowApi.middleware)
            .concat(competitorsApi.middleware)
            .concat(monitorApi.middleware)
            .concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
